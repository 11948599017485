<template>
  <v-dialog :value="internalValue" width="1000px" persistent @input="$emit('input', false)">
    <v-card v-if="internalValue" max-height="800" class="dialog-content d-flex flex-column">
      <v-card-title>
        <span class="block-title">Editando a loja: {{ lazyUnit.name }}</span>
        <v-spacer />
        <v-icon @click="cancel">mdi-close-thick</v-icon>
      </v-card-title>
      <v-card-text class="pt-0 flex-grow-1 overflow-y-auto">
        <div>
          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="5">
              <label class="detail-title">Razão Social*</label>
              <v-text-field v-model="lazyUnit.corporate_name" :error-messages="errorMessages.corporate_name" outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="4">
              <label class="detail-title">Nome*</label>
              <v-text-field v-model="lazyUnit.name" :error-messages="errorMessages.name" outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">CNPJ*</label>
              <v-text-field v-model="lazyUnit.cnpj" v-mask="'##.###.###/####-##'" :error-messages="errorMessages.cnpj" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="6">
              <label class="detail-title">Insc. Estadual</label>
              <v-text-field v-model="lazyUnit.statual_insc" outlined />
            </v-col>

            <v-col class="pa-0 pr-6" cols="6">
              <label class="detail-title">Insc. Municipal</label>
              <v-text-field v-model="lazyUnit.municipal_insc" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">CEP*</label>
              <v-text-field v-model="internalPostalCode" v-mask="'#####-###'" :loading="loadingCep" :error-messages="errorMessages.postal_code" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Estado*</label>
              <v-select
                v-model="address.federative_unit"
                label="Estado"
                :items="estados"
                item-text="name"
                item-value="value"
                :error-messages="errorMessages.federative_unit"
                outlined
                single-line
              />
            </v-col>
            <v-col class="pa-0 pr-6" cols="3">
              <label class="detail-title">Cidade*</label>
              <v-select
                v-model="address.city_name"
                label="Cidade"
                :items="cidadesFiltradas"
                item-text="Nome"
                item-value="Nome"
                :error-messages="errorMessages.city_name"
                outlined
                single-line
              />
              <!-- :disabled="address.federative_unit === ''" -->
            </v-col>
            <v-col class="pa-0 pr-3" cols="3">
              <label class="detail-title">Bairro*</label>
              <v-text-field v-model="address.neighborhood" :error-messages="errorMessages.neighborhood" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0 pr-6" cols="5">
              <label class="detail-title">Rua*</label>
              <v-text-field v-model="address.street" :error-messages="errorMessages.street" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="2">
              <label class="detail-title">Número*</label>
              <v-text-field v-model="address.number" :error-messages="errorMessages.number" outlined />
            </v-col>
            <v-col class="pa-0 pr-6" cols="2">
              <label class="detail-title">Latitude*</label>
              <v-text-field v-model="latitude" :error-messages="errorMessages.latitude" outlined suffix="°" />
            </v-col>
            <v-col class="pa-0 pr-6" cols="2">
              <label class="detail-title">Longitude*</label>
              <v-text-field v-model="longitude" :error-messages="errorMessages.longitude" outlined suffix="°" />
            </v-col>
            <v-col class="pa-0 pr-3" cols="1">
              <label class="detail-title">Atualizar</label>
              <v-btn :loading="loadingCoordinates" dense class="mt-3 px-0" color="primary" @click="getCoordinates">
                <mf-icon color="white">refresh</mf-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0" cols="12">
              <label class="detail-title">Complemento</label>
              <v-text-field v-model="address.complement" :error-messages="errorMessages.complement" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pa-0" cols="12">
              <label class="detail-title">Endereço completo*</label>
              <v-text-field v-model="internalFullAddress" :error-messages="errorMessages.address" outlined />
            </v-col>
          </v-row>

          <v-row class="pl-3">
            <v-col class="pl-0 py-0" cols="6">
              <label class="detail-title">Segmento</label>
              <v-select
                v-model="lazyUnit.segments"
                :error-messages="errorMessages.segments"
                multiple
                :items="segments"
                item-text="text"
                item-value="text"
                outlined
              />
            </v-col>
            <v-col class="py-0" cols="3">
              <label class="detail-title">Total PDV</label>
              <mf-number-input v-model="lazyUnit.total_pdv_amount" show-arrows :min="0" outlined />
            </v-col>
            <v-col class="py-0" cols="3">
              <label class="detail-title">Total PDV contradado</label>
              <mf-number-input v-model="lazyUnit.total_pdv_contracted" show-arrows :min="0" outlined />
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">CBM</label>
              <mf-tooltip bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox v-model="lazyUnit.cbm" class="mt-0" disabled label="CBM ativo" />
                </template>
                <span>As flags de produtos disponíveis só podem ser alteradas com novas vendas.</span>
              </mf-tooltip>
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Mercashop</label>
              <mf-tooltip bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox v-model="lazyUnit.mercashop" class="mt-0" disabled label="Mercashop ativo" />
                </template>
                <span>As flags de produtos disponíveis só podem ser alteradas com novas vendas.</span>
              </mf-tooltip>
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Mercapromo</label>
              <mf-tooltip bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox v-model="lazyUnit.mercapromo" class="mt-0" disabled label="Mercapromo ativo" />
                </template>
                <span>As flags de produtos disponíveis só podem ser alteradas com novas vendas.</span>
              </mf-tooltip>
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Promotrade</label>
              <mf-tooltip bottom max-width="250">
                <template #activator="{ on }">
                  <v-checkbox v-model="lazyUnit.promotrade" class="mt-0" disabled label="Promotrade ativo" />
                </template>
                <span>As flags de produtos disponíveis só podem ser alteradas com novas vendas.</span>
              </mf-tooltip>
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Loja Principal</label>
              <v-switch v-model="lazyUnit.store_main" :label="`Sim`" />
            </v-col>
            <v-col class="pa-0" cols="3">
              <label class="detail-title">Loja Teste</label>
              <v-checkbox v-model="lazyUnit.is_test" label="Loja teste" class="m-0" />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <mf-action-buttons
          :primary-button="{
            text: 'Salvar',
            action: save,
            isVisible: true,
            isLoading: saving
          }"
          :extra-button="{
            text: 'Cancelar',
            action: cancel,
            isVisible: true,
            isDisabled: saving
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { getDataFromViaCep } from '@/helpers/cep'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { notOnlySpace, validCnpj } from '@/helpers/validators.js'
import { data as cidades } from '@/assets/data/cidades.json'
import { data as estados } from '@/assets/data/estados.json'
import { retailSegments } from '@/mixin'
import { QUERY_GET_COORDINATES } from '@/modules/retailers/graphql'
import config from '@/config'

const DEFAULT_ADDRESS = {
  postal_code: '',
  federative_unit: '',
  city_name: '',
  neighborhood: '',
  street: '',
  number: ''
}

export default {
  name: 'EditUnit',
  mixins: [retailSegments],

  props: {
    saveCallback: Function,
    value: Boolean,
    unit: Object,
    isNew: Boolean
  },
  data: () => ({
    loadingCoordinates: false,
    lazyValue: false,
    lazyUnit: {},
    saving: false,
    latitude: '',
    longitude: '',
    localFullAddress: '',
    cidades: cidades,
    estados: estados,
    fullAddressTouched: false,
    postalCodeTouched: false,
    loadingCep: false,
    address: DEFAULT_ADDRESS
  }),
  computed: {
    internalPostalCode: {
      get() {
        return this.address.postal_code
      },
      set(value) {
        const newRaw = value.replace(/[^\d]+/g, '')
        const oldRaw = this.address.postal_code.replace(/[^\d]+/g, '')
        if (newRaw !== oldRaw) {
          this.postalCodeTouched = true
        }
        this.address.postal_code = value
      }
    },
    internalFullAddress: {
      get() {
        if (this.fullAddressTouched) {
          return this.localFullAddress
        }
        if (this.lazyUnit.address) {
          return this.lazyUnit.address
        }
        return this.defaultFullAddress
      },
      set(value) {
        this.fullAddressTouched = true
        this.localFullAddress = value
      }
    },
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(value) {
        this.lazyValue = value
        this.$emit('input', value)
      }
    },
    defaultFullAddress() {
      const fullAddressComponents = []
      if (this.address.street) {
        fullAddressComponents.push(this.address.street)
      }

      if (this.address.number || this.address.neighborhood) {
        fullAddressComponents.push([this.address.number, this.address.neighborhood].filter(el => Boolean(el)).join(' - '))
      }

      if (this.address.city_name || this.address.federative_unit) {
        fullAddressComponents.push([this.address.city_name, this.address.federative_unit].filter(el => Boolean(el)).join(' - '))
      }

      if (this.address.postal_code) {
        fullAddressComponents.push(this.address.postal_code)
      }
      return fullAddressComponents.join(', ')
    },
    cidadesFiltradas() {
      return this.cidades.filter(cidade => cidade.Uf === this.address.federative_unit)
    },
    errorMessages() {
      const fields = ['cnpj', 'corporate_name', 'name', 'segments']
      const errors = fields.reduce((errors, field) => {
        if (this.$v.lazyUnit[field] && this.$v.lazyUnit[field].$error) {
          if (this.$v.lazyUnit[field].required === false) errors[field] = 'Campo obrigatório.'
          else if (this.$v.lazyUnit[field].notOnlySpace === false) errors[field] = 'Não é permitido salvar apenas espaços.'
        } else {
          errors[field] = ''
        }
        return errors
      }, {})
      if (!errors.cnpj && !this.$v.lazyUnit.cnpj.validCnpj) errors.cnpj = 'CNPJ inválido.'
      if (this.$v.internalFullAddress.$error) errors['address'] = 'Endereço completo inválido.'
      if (this.$v.address.postal_code.$error) errors['postal_code'] = 'CEP inválido.'
      if (this.$v.address.federative_unit.$error) errors['federative_unit'] = 'Estado inválido.'
      if (this.$v.address.city_name.$error && !this.$v.address.federative_unit.$error) errors['city_name'] = 'Cidade inválida.'
      if (this.$v.address.neighborhood.$error) errors['neighborhood'] = 'Bairro inválido.'
      if (this.$v.address.street.$error) errors['street'] = 'Rua inválida.'
      if (this.$v.address.number.$error) errors['number'] = 'Número inválido.'
      if (this.$v.latitude.$error) errors['latitude'] = 'Latitude inválida.'
      if (this.$v.longitude.$error) errors['longitude'] = 'Longitude inválida.'
      return errors
    }
  },
  watch: {
    value(value) {
      this.lazyValue = value
      // Reset field
      if (value) {
        this.setupUnit(this.unit)
      }
    },
    unit(value) {
      this.setupUnit(value)
    },
    async 'address.postal_code'(value) {
      const raw = value.replace(/[^\d]+/g, '')
      if (raw.length !== 8 || !this.postalCodeTouched) {
        return
      }
      try {
        this.loadingCep = true
        const cepData = await getDataFromViaCep(raw)
        this.address.federative_unit = cepData.uf || ''
        this.address.city_name = cepData.localidade || ''
        this.address.neighborhood = cepData.bairro || ''
        this.address.street = cepData.logradouro || ''
      } catch (e) {
        this.$snackbar({ message: 'Falha ao procurar o CEP' })
      }
      this.loadingCep = false
    }
  },
  mounted() {
    this.lazyValue = this.value
    this.setupUnit(this.unit)
  },
  methods: {
    setupUnit(unit) {
      this.postalCodeTouched = false
      this.lazyUnit = cloneDeep(unit)
      if (this.lazyUnit?.location?.coordinates) {
        this.latitude = String(this.lazyUnit.location.coordinates[0]).replace('.', ',')
        this.longitude = String(this.lazyUnit.location.coordinates[1]).replace('.', ',')
      }
      if (this.lazyUnit?.address_components) {
        this.address = this.lazyUnit.address_components
      } else {
        this.address = cloneDeep(DEFAULT_ADDRESS)
      }
      // default fields
      if (this.lazyUnit) {
        this.lazyUnit.municipal_insc = this.lazyUnit.municipal_insc || ''
        this.lazyUnit.statual_insc = this.lazyUnit.statual_insc || ''
        this.lazyUnit.total_pdv_amount = this.lazyUnit.total_pdv_amount || 0
        this.lazyUnit.total_pdv_contracted = this.lazyUnit.total_pdv_contracted || 0
        this.lazyUnit.is_test = this.lazyUnit.is_test || false
      }
    },
    async getCoordinates() {
      try {
        this.loadingCoordinates = true
        const { data } = await this.$apollo.query({
          query: QUERY_GET_COORDINATES,
          variables: {
            geocode_input: {
              address: {
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city: this.address.city_name,
                state: this.address.federative_unit,
                country: 'Brasil',
                postalCode: this.address.postal_code
              }
            }
          },
          fetchPolicy: 'no-cache',
          context: {
            uri: this.$microservicesUrls['federation'],
            headers: { authorization: `Basic ${config.geo.basicAuthentication}` }
          }
        })
        this.latitude = data.geocode.address.lat.replace('.', ',')
        this.longitude = data.geocode.address.lng.replace('.', ',')
      } catch (e) {
        this.$snackbar({ message: 'Falha ao resolver geolocalização', snackbarColor: 'error' })
      }
      this.loadingCoordinates = false
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$error) {
        console.log(this.$v)
        return this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: '#FF5252' })
      }
      const confirmed = await this.$confirm({
        message: 'Todas as alterações serão refletidas na loja nas plataformas atreladas do varejista. Deseja realmente editar a unidade?',
        confirmColor: 'success',
        confirmText: 'Sim',
        cancelText: 'Voltar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmed) return
      this.saving = true
      try {
        const addressComponents = cloneDeep(this.address)
        addressComponents.postal_code = addressComponents.postal_code.replace(/[^\d]+/g, '')
        const unit = {
          corporate_name: this.lazyUnit.corporate_name,
          name: this.lazyUnit.name,
          cnpj: this.lazyUnit.cnpj.replace(/[^\d]+/g, ''),
          municipal_insc: this.lazyUnit.municipal_insc,
          statual_insc: this.lazyUnit.statual_insc,
          segments: this.lazyUnit.segments,
          total_pdv_amount: this.lazyUnit.total_pdv_amount,
          total_pdv_contracted: this.lazyUnit.total_pdv_contracted,
          store_main: this.lazyUnit.store_main,
          address_components: addressComponents,
          address: this.internalFullAddress,
          location: {
            type: 'Point',
            coordinates: [parseFloat(this.latitude.replace(',', '.')), parseFloat(this.longitude.replace(',', '.'))]
          },
          is_test: this.lazyUnit.is_test
        }
        await this.saveCallback(unit, this.unit?._id)
      } catch (e) {
        this.$snackbar({ message: 'Falha ao salvar alterações', snackbarColor: 'error' })
      }
      this.saving = false
      this.internalValue = false
      this.$emit('close')
    },
    cancel() {
      this.internalValue = false
      this.lazyUnit = {}
      this.$emit('close')
    }
  },
  validations: {
    latitude: {
      validNumber: latitude => latitude && !isNaN(latitude.replace(',', '.'))
    },
    longitude: {
      validNumber: longitude => longitude && !isNaN(longitude.replace(',', '.'))
    },
    lazyUnit: {
      corporate_name: { required, notOnlySpace },
      name: { required, notOnlySpace },
      cnpj: { required, validCnpj },
      segments: { required }
    },
    internalFullAddress: { required, notOnlySpace },
    address: {
      postal_code: { required, notOnlySpace, minLength: minLength(9), maxLength: maxLength(9) },
      federative_unit: { required, notOnlySpace },
      city_name: { required, notOnlySpace },
      neighborhood: { required, notOnlySpace },
      street: { required, notOnlySpace },
      number: { required, notOnlySpace }
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  .block-title {
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }
  .detail-title {
    opacity: 0.8;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    color: #000000;
    margin-bottom: 8px;
  }

  .office-hours-title {
    padding: 0 0 0 12px;
    span {
      font-size: 1em;
      font-weight: bold;
    }
  }
  .closed-office-hours {
    text-decoration: line-through;
  }
  .office-hours-content-chips {
    .office-hours-chip {
      margin-bottom: 3px;
      padding-right: 0px;
      padding-left: 10px;
      span {
        padding-right: 5px;
      }
      .clickable {
        cursor: pointer;
      }
    }
  }
  .office-hours-content-new {
    padding-top: 5px;
    .office-hours-content-new-input {
      font-size: 0.75em;
      border: 1px solid #cecece;
      height: 20px;
      width: 50px;
      display: block;
    }
    i {
      padding-left: 5px;
    }
  }
}
</style>
